<template>
  <v-col cols="12" md="1" class="d-flex pa-0 justify-center align-center ma-0 my-auto">
    <v-img
      height="40px"
      :class="{
        'rotate-90deg': orientation === 'down',
        'rotate-180deg': orientation === 'left',
        'rotate-270deg': orientation === 'up',
      }"
      :alt="t('arrow')"
      :src="dottedArrow" />
  </v-col>
</template>

<script setup lang="ts">
import dottedArrow from '@/assets/icons/footer/footer_arrow.svg'
const { t } = useI18n()

type Props = {
  orientation: 'up' | 'down' | 'left' | 'right'
}

withDefaults(defineProps<Props>(), {
  orientation: 'right',
})
</script>

<style scoped lang="scss">
.rotate-90deg {
  transform: rotate(90deg);
}

.rotate-180deg {
  transform: rotate(180deg);
}

.rotate-270deg {
  transform: rotate(270deg);
}
</style>
